import { useEffect } from 'react';

export const useSycurioMessageListener = ({
  callback,
}: {
  callback: (message: MessageEvent) => void;
}) => {
  useEffect(() => {
    window.addEventListener('message', callback, false);

    return () => {
      window.removeEventListener('message', callback);
    };
  }, []);
};
