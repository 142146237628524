import React from 'react';
import { Box, Typography } from '@mui/material';

import { FORM_FIELD_SUFFIXES_IDS } from '../../utils/element-ids';
import HintText from '../HintText/HintText';

export type FieldLabelProps = {
  id: string;
  label: string;
  hintText?: string;
  isUsedByRadioGroup?: boolean;
};

const FieldLabel = ({
  id,
  label,
  hintText = '',
  isUsedByRadioGroup = false,
}: FieldLabelProps) => {
  return (
    <Box>
      <Typography
        variant="body1"
        display="inline"
        component="label"
        data-testid={`${id}_${FORM_FIELD_SUFFIXES_IDS.FIELD_LABEL}`}
        {...(isUsedByRadioGroup ? { id } : { htmlFor: id })}
      >
        <strong>{label}</strong>
        {hintText ? (
          <HintText
            id={id}
            hintText={hintText}
          />
        ) : null}
      </Typography>
    </Box>
  );
};

export default FieldLabel;
