import { getConfiguredChannelsForAgent } from '../../session/getConfiguredChannelsForAgent';
import { getConfigMode } from '../../session/selectors';
import paymentMethodEntryViewState from '../paymentMethodEntryViewState';

import getBackButtonVisibilityState from './getBackButtonVisibilityState';
import type GetBackButtonDetailsArgs from './types/GetBackButtonDetailsArgs';
import type GetBackButtonDetailsReturn from './types/GetBackButtonDetailsReturn';
import hasMultipleChannels from './utils/hasMultipleChannels';
import {
  BACK_TO_WALLET_RESULT,
  BACK_TO_CHANNEL_SELECTOR_RESULT,
  BACK_TO_WALLET_MANAGEMENT_RESULT,
  HIDE_BACK_BUTTON,
  BACK_TO_SYCURIO_TELEPHONIC_ENTRY,
} from './utils/resultsForGetBackButtonDetails';

const getBackButtonDetails = ({
  errorType,
  sessionDetails,
  componentEntryLocalData,
  containerConfig,
}: GetBackButtonDetailsArgs): GetBackButtonDetailsReturn => {
  const viewState = paymentMethodEntryViewState({
    errorType,
    checkoutSessionDetails: sessionDetails,
    componentEntryLocalData,
  });

  // 1. back button: perform visibility check
  const isBackButtonVisible = getBackButtonVisibilityState({
    viewState,
    sessionDetails,
    containerConfig,
  });

  if (!isBackButtonVisible) {
    return HIDE_BACK_BUTTON;
  }

  const configMode = getConfigMode(sessionDetails);

  // 2. back button: determine navigation path
  switch (viewState) {
    case 'sycurio_telephonic_entry':
    case 'unified_payment_method_form': {
      if (
        hasMultipleChannels(sessionDetails) &&
        [
          'unified_payment_method_form',
          'sycurio_telephonic_entry',
        ].includes(viewState)
      ) {
        return BACK_TO_CHANNEL_SELECTOR_RESULT;
      }

      if (configMode === 'PAYMENT_WITH_WALLET') {
        return BACK_TO_WALLET_RESULT;
      }

      if (
        ['WALLET', 'PAYMENT_METHOD_SELECT'].includes(configMode)
      ) {
        return BACK_TO_WALLET_MANAGEMENT_RESULT;
      }

      return HIDE_BACK_BUTTON;
    }
    case 'channel_selector': {
      if (configMode === 'PAYMENT_WITH_WALLET') {
        return BACK_TO_WALLET_RESULT;
      }

      if (
        ['WALLET', 'PAYMENT_METHOD_SELECT'].includes(configMode)
      ) {
        return BACK_TO_WALLET_MANAGEMENT_RESULT;
      }
      return HIDE_BACK_BUTTON;
    }
    case 'notifications_methods': {
      if (hasMultipleChannels(sessionDetails)) {
        return BACK_TO_CHANNEL_SELECTOR_RESULT;
      }

      if (configMode === 'PAYMENT_WITH_WALLET') {
        return BACK_TO_WALLET_RESULT;
      }

      if (
        ['WALLET', 'PAYMENT_METHOD_SELECT'].includes(configMode)
      ) {
        return BACK_TO_WALLET_MANAGEMENT_RESULT;
      }
      return HIDE_BACK_BUTTON;
    }
    case 'sycurio_phone_entry_submit_page': {
      return BACK_TO_SYCURIO_TELEPHONIC_ENTRY;
    }
    case 'view_state_error': {
      if (
        ['PAYMENT_METHOD_ENTRY', 'PAYMENT'].includes(
          configMode,
        ) &&
        hasMultipleChannels(sessionDetails) &&
        getConfiguredChannelsForAgent(sessionDetails)
          .map((channels) => channels.type)
          .includes('TELEPHONIC_ENTRY')
      ) {
        return BACK_TO_CHANNEL_SELECTOR_RESULT;
      }

      if (configMode === 'PAYMENT_WITH_WALLET') {
        return BACK_TO_WALLET_RESULT;
      }

      if (
        ['WALLET', 'PAYMENT_METHOD_SELECT'].includes(configMode)
      ) {
        return BACK_TO_WALLET_MANAGEMENT_RESULT;
      }

      return HIDE_BACK_BUTTON;
    }
    default: {
      return HIDE_BACK_BUTTON;
    }
  }
};

export default getBackButtonDetails;
