import { useState, useEffect, useContext } from 'react';

import { AppContext } from '../capabilities/contextStore/AppContext';
import type ComponentEntryLocalData from '../shared/types/component-entry/ComponentEntryLocalData';
import { getFilteredPaymentTypes } from '../utils/capabilities/getFilteredPaymentTypes';
import { getExpressCheckoutMerchantConfig } from '../utils/session/getExpressCheckoutMerchantConfig';
import getValueForSelectedChannel from '../utils/session/getValueForSelectedChannel';
import {
  getAgent,
  getChildSessionId,
  getChildSessionStatus,
  getCustomerId,
  getFirstDestination,
  getPaymentMethodId,
  getSaveDisabledFlag,
  isChildSessionValid,
} from '../utils/session/selectors';
import type { ErrorType } from '../utils/capabilities/paymentMethodEntryViewState/paymentMethodEntryViewState';

export default function useComponentEntry() {
  const [errorType, setErrorType] = useState<ErrorType>();
  const [shouldCancelChildSession, setShouldCancelChildSession] =
    useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const {
    checkoutSessionId,
    vendorPlatformKey,
    paymentDescription,
    amount,
    merchantTransactionId,
    authorizeCard,
    statementDescriptorSuffix,
    setData: setApplicationData,
    originalCheckoutSessionResponse,
  } = useContext(AppContext);
  const [localData, setLocalData] =
    useState<ComponentEntryLocalData>({
      isBackToChannelSelection: false,
      isBackToPaymentMethodSelection: false,
      childSessionHistory: [],
      checkoutSessionId,
    });
  const [isChildStatusCanceled, setIsChildStatusCanceled] =
    useState<boolean>(false);

  /** !! Important: !!
   *  Definition of various actors related to our widget (as per John Kaldor):
   *  - Guest: no agent object, no cashier, no customerId property.
   *  - Agent Assisted Session: agent object or cashierId (cashier will be deprecated), customerId property.
   *  - Authenticated User: customerId property, no agent object, no cashier.
   *  - Invalid User: agent object, no customerId property.
   *
   *  Below are the various conditions to use:
   */

  const agent = !!getAgent(originalCheckoutSessionResponse);
  const customerId = getCustomerId(
    originalCheckoutSessionResponse,
  );

  const isAuthenticatedUser = !!customerId && !agent;
  const isGuestUser = !agent && !customerId;
  const isAgentAssistedSession = !!agent && !!customerId;
  const isInvalidUser = !!agent && !customerId;

  const filteredPaymentMethodTypes = getFilteredPaymentTypes({
    session: originalCheckoutSessionResponse,
  });

  const showSaveFeatureBlock = getSaveDisabledFlag(
    originalCheckoutSessionResponse,
  );

  const { isGooglePayButtonEnabled, isApplePayButtonEnabled } =
    getExpressCheckoutMerchantConfig(
      originalCheckoutSessionResponse,
    );

  const selectedChannelType = getValueForSelectedChannel(
    originalCheckoutSessionResponse,
  );

  const paymentMethodId = getPaymentMethodId(
    originalCheckoutSessionResponse,
  );

  const childSessionStatus = getChildSessionStatus(
    originalCheckoutSessionResponse,
  );

  const childSessionId = getChildSessionId(
    originalCheckoutSessionResponse,
  );

  useEffect(() => {
    const isChildSessionValidResult = isChildSessionValid(
      originalCheckoutSessionResponse,
    );

    setLocalData((prev) => ({
      ...prev,
      childSessionId: isChildSessionValidResult
        ? getChildSessionId(originalCheckoutSessionResponse)
        : undefined,
      // The isChildSessionValid check happens inside getValueForSelectedChannel
      selectedChannelType,
      notificationDestination: isChildSessionValidResult
        ? getFirstDestination(originalCheckoutSessionResponse)
        : undefined,
      paymentMethodId,
      childSessionHistory:
        childSessionId && childSessionStatus === 'COMPLETED'
          ? [childSessionId]
          : [],
    }));

    setIsInitializing(false);
  }, []);

  return {
    isAgentAssistedSession,
    isAuthenticatedUser,
    isGuestUser,
    isInvalidUser,
    localData,
    isInitializing,
    errorType,
    shouldCancelChildSession,
    isChildStatusCanceled,
    setErrorType,
    setShouldCancelChildSession,
    setIsChildStatusCanceled,
    checkoutSessionId,
    vendorPlatformKey,
    paymentDescription,
    amount,
    merchantTransactionId,
    authorizeCard,
    statementDescriptorSuffix,
    setApplicationData,
    originalCheckoutSessionResponse,
    setLocalData,
    filteredPaymentMethodTypes,
    showSaveFeatureBlock,
    isGooglePayButtonEnabled,
    isApplePayButtonEnabled,
  };
}
