export const hasCapturedPaymentMethodResponse = (
  data: string,
) => {
  try {
    /** todo: will uncomment the below items once sycurio adds in the postMessage */
    const keysToCheck = [
      'token',
      // 'nameOnCard',
      // 'lastFour',
      // 'expYear',
      // 'expMonth',
      // 'brand',
      // 'zipOfCard',
    ];
    const parsedData = JSON.parse(data);
    const missingKeys = keysToCheck.filter(
      (key) => !(key in parsedData),
    );
    return missingKeys.length === 0;
  } catch (error) {
    console.error('Error parsing Sycurio PCI response');
  }
  return false;
};
