import React from 'react';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PaymentOutlined from '@mui/icons-material/PaymentOutlined';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

import type {
  PaymentMethodChannelType,
  PaymentMethodChannelUiConfig,
} from '../../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';

const PaymentMethodChannelConfig: Record<
  Exclude<PaymentMethodChannelType, 'GOOGLE_PAY' | 'APPLE_PAY'>,
  PaymentMethodChannelUiConfig
> = {
  TEXT: {
    icon: <TextsmsOutlinedIcon />,
    title: 'Send a link via text',
  },
  EMAIL: {
    icon: <EmailOutlinedIcon />,
    title: 'Send a link via email',
  },
  WEBFORM: {
    icon: <DevicesOutlinedIcon />,
    title: 'On-screen entry',
  },
  TELEPHONIC_ENTRY: {
    icon: <PhoneInTalkIcon />,
    title: 'Add via phone entry',
  },
  TERMINAL: {
    icon: <PaymentOutlined />,
    title: 'Terminal entry',
  },
};

export default PaymentMethodChannelConfig;
