import type {
  CheckoutSessionsResponse,
  Agent,
} from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { ChildSessionStatus } from '../../services/commonCheckout/types/ChildSessionStatus';
import type { UiModes } from '../../services/commonCheckout/types/UiModes';
import type { MerchantConsentText } from '../../capabilities/add-payment-method/types/MerchantConsentTextType';
import type { ConsentObject } from '../../capabilities/checkout-v2/utils/createPaymentConsentObject';

export const getPaymentMethodTypes = (
  session: CheckoutSessionsResponse,
) =>
  session.checkoutSession.checkoutRequest.config?.paymentMethod.map(
    (config) => config.type,
  ) ?? [];

export const getPaymentMethods = (
  session: CheckoutSessionsResponse,
) =>
  session.checkoutSession.checkoutRequest.config?.paymentMethod;

export const getChildSessionId = (
  session: CheckoutSessionsResponse,
): string | undefined =>
  session.checkoutSession.childSession?.id;

/**
 * Does not validate return type.
 *
 * Assumes you have validated config.modes upon receipt from server
 */
export const getConfigMode = (
  checkoutSessionResponse: CheckoutSessionsResponse,
) =>
  checkoutSessionResponse.checkoutSession?.checkoutRequest.config
    ?.modes[0] as UiModes;

export const getPaymentMethodId = (
  session: CheckoutSessionsResponse,
): string | undefined =>
  session.checkoutSession.paymentMethod?.id;

export const getIIASPaymentDetails = (
  session: CheckoutSessionsResponse,
) =>
  session.checkoutSession.checkoutRequest.paymentDetails ?? null;

export const getPhoneNumber = (
  session: CheckoutSessionsResponse,
): string | undefined => {
  let { phoneNumber } = session.checkoutSession.checkoutRequest;
  if (phoneNumber) {
    // The backend returns the phone number with the country code, we return the last 10 digits
    phoneNumber = phoneNumber.slice(-10);
  }
  return phoneNumber;
};

export const getPaymentMethodIdFromRequest = (
  session: CheckoutSessionsResponse,
): string | null | undefined => {
  return session?.checkoutSession?.checkoutRequest?.paymentMethod
    ?.id;
};

export const getPreferredLanguage = (
  session: CheckoutSessionsResponse,
): 'en' | 'es' => {
  const preferredLanguage =
    session.checkoutSession?.checkoutRequest?.config?.options
      ?.preferredLanguage;
  if (
    preferredLanguage &&
    (preferredLanguage === 'en' || preferredLanguage === 'es')
  ) {
    return preferredLanguage;
  }
  return 'en';
};

export const getSessionId = (
  session: CheckoutSessionsResponse,
): string => session.checkoutSession.id;

export const getChildSessionStatus = ({
  checkoutSession,
}: CheckoutSessionsResponse): ChildSessionStatus =>
  checkoutSession.childSession?.status ?? 'INITIAL';

export const getFirstDestination = (
  session: CheckoutSessionsResponse,
): string | undefined => {
  // This can be email or phone number
  let destination: string | undefined =
    session.checkoutSession.childSession?.notification
      ?.destination[0];

  if (destination && !destination.includes('@')) {
    /**
     * 1. Assume this is a phone number if we're inside the if statement
     * 2. The backend is returning phone number in the following format:
     * +########### (11 digits, prefixed with +), we return the last 10 digits
     */

    destination = destination.slice(-10);
  }

  return destination;
};

export const getNotificationChannel = (
  session: CheckoutSessionsResponse,
) => session.checkoutSession.childSession?.notification?.channel;

export const isChildSessionValid = (
  session: CheckoutSessionsResponse,
) => {
  const childSession =
    session.checkoutSession.childSession?.status;
  return (
    childSession &&
    childSession !== 'EXPIRED' &&
    childSession !== 'FAILED' &&
    childSession !== 'CANCELED' &&
    childSession !== 'NOTIFICATION_FAILED'
  );
};

export const isChildSessionExpired = (
  session: CheckoutSessionsResponse,
) => {
  return (
    session.checkoutSession.childSession?.status === 'EXPIRED'
  );
};

export const getEmailAddress = (
  session: CheckoutSessionsResponse,
): string | undefined => {
  const { email } = session.checkoutSession.checkoutRequest;
  return email;
};

export const getAgent = (
  session: CheckoutSessionsResponse,
): Agent | null => {
  return session?.checkoutSession?.checkoutRequest?.agent;
};

export const getAssistedByAgent = (
  session: CheckoutSessionsResponse,
): Agent | null => {
  const { assistedByAgent } =
    session.checkoutSession.checkoutRequest;
  return assistedByAgent;
};

export const getMetadata = (session: CheckoutSessionsResponse) =>
  session.checkoutSession.checkoutRequest.metadata;

export const getCustomerId = (
  session: CheckoutSessionsResponse,
) => session.checkoutSession.customerId;

export const getWarning = (session: CheckoutSessionsResponse) =>
  session.checkoutSession.warning;

export const getWarningForAgent = (
  session: CheckoutSessionsResponse,
) => session.checkoutSession?.paymentMethod?.warning;

export const getMerchantConsentID = (
  session: CheckoutSessionsResponse,
): string | undefined | null =>
  session.checkoutSession.checkoutRequest?.consent
    ?.merchantConsentId;

export const getMerchantConsentText = (
  session: CheckoutSessionsResponse,
): MerchantConsentText => {
  return session.checkoutSession.checkoutRequest?.consent
    ?.merchantConsentText;
};

export const getCollectionDetailsTel = (
  session: CheckoutSessionsResponse,
):
  | Extract<ConsentObject['collectionDetails'], { type: 'TEL' }>
  | undefined => {
  if (
    session.checkoutSession.checkoutRequest.consent
      ?.collectionDetails?.type === 'TEL'
  ) {
    return session.checkoutSession.checkoutRequest.consent
      ?.collectionDetails;
  }
  return undefined;
};
export const getPrivacyPolicyUrl = (
  session: CheckoutSessionsResponse,
): string | null | undefined =>
  session.checkoutSession.checkoutRequest.config
    ?.privacyPolicyUrl;

export const getUserAgent = () => window.navigator.userAgent;

export const getSaveDisabledFlag = (
  session: CheckoutSessionsResponse,
): boolean => {
  return (
    session.checkoutSession?.checkoutRequest?.config?.options
      ?.saveDisabled !== true
  );
};

export const getExpiresAtUtc = (
  session: CheckoutSessionsResponse,
) => session.checkoutSession.expiresAtUtc;

export const getPaymentMethodDisplayDetails = (
  session: CheckoutSessionsResponse,
) => session.checkoutSession.paymentMethod;

export const getPartialAuthorization = (
  session: CheckoutSessionsResponse,
) =>
  session.checkoutSession.checkoutRequest.partialAuthorization ??
  false;

export const getMerchantName = (
  session: CheckoutSessionsResponse,
) => session.checkoutSession.merchant?.name;

export const getSycurioTelephonicEntryDetails = ({
  checkoutSession: { telephonicEntryDetails },
}: CheckoutSessionsResponse) => telephonicEntryDetails;

export const getSycurioTelephonicEntryErrorMessage = ({
  checkoutSession: { telephonicEntryDetails },
}: CheckoutSessionsResponse) =>
  telephonicEntryDetails?.status === 'FAILED' &&
  telephonicEntryDetails?.error
    ? telephonicEntryDetails.error.message
    : 'There was an error connecting to the telephonic entry service. Please try again.';
