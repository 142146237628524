import { appInsights } from '../app-insights';
import { telemetryKeyPrefix } from '../utils/telemetryKeyPrefix';

import type { SycurioError } from './types';

export const logSycurioError = ({
  errorType,
  error,
  metadata = {},
}: SycurioError) => {
  const { code, message } = metadata;

  appInsights.traceError(error, {
    [telemetryKeyPrefix('sycurioErrorType')]: errorType,
    [telemetryKeyPrefix('sycurioErrorCode')]: code,
    [telemetryKeyPrefix('sycurioErrorMessage')]: message,
  });
};
