import type { SycurioPCIResponseType } from './getSycurioPCIResponse';

export const hasValidPCIResponse = (
  sycurioPCIResponse: SycurioPCIResponseType,
): boolean => {
  return (
    !!sycurioPCIResponse.token &&
    !!sycurioPCIResponse.lastFour &&
    !!sycurioPCIResponse.expYear &&
    !!sycurioPCIResponse.expMonth &&
    !!sycurioPCIResponse.brand &&
    !!sycurioPCIResponse.zipOfCard &&
    !!sycurioPCIResponse.nameOnCard
  );
};
