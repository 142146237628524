import React from 'react';
import { Typography } from '@mui/material';

import { FORM_FIELD_SUFFIXES_IDS } from '../../utils/element-ids';

export type HintTextProps = {
  id: string;
  hintText?: string;
};

const HintText = ({ id, hintText = '' }: HintTextProps) => {
  return (
    <Typography
      variant="body2"
      data-testid={`${id}_${FORM_FIELD_SUFFIXES_IDS.HINT_TEXT}`}
      display="inline"
    >
      {` ${hintText}`}
    </Typography>
  );
};

export default HintText;
