export const isMessageFromSycurio = <T>({
  event,
  sycurioHttpOrigin,
}: {
  event: MessageEvent<T>;
  sycurioHttpOrigin: string;
}) =>
  event.origin === sycurioHttpOrigin &&
  !!event.data &&
  typeof event.data === 'string';
