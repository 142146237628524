import React from 'react';
import { Typography } from '@mui/material';

import { FORM_FIELD_SUFFIXES_IDS } from '../../utils/element-ids';

export type HelperTextProps = {
  id: string;
  helperText?: string;
};

const HelperText = ({
  id,
  helperText = '',
}: HelperTextProps) => {
  return (
    <Typography
      variant="body2"
      data-testid={`${id}_${FORM_FIELD_SUFFIXES_IDS.HELPER_TEXT}`}
    >
      {helperText}
    </Typography>
  );
};

export default HelperText;
