/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Box } from '@mui/material';

import StripeApi from '../../services/stripe/stripe';
import PaymentApi from '../../services/payment/PaymentApi';
import CancelCallbackContext from '../checkout-v2/context/cancel-callback-context';
import { NotificationContext } from '../contextStore/NotificationContext';
import { serviceErrorNotification } from '../../ui/NotificationAlert/serviceErrorNotification';
import ErrorCard from '../../components/ErrorCard/ErrorCard';
import AwaitingUserResponseContainer from '../components/AwaitingUserResponse/AwaitingUserResponseContainer';
import CancelChildSessionWrapper from '../../shared/CancelChildSessionWrapper/CancelChildSessionWrapper';
import {
  getPhoneNumber,
  getEmailAddress,
  getSessionId,
  getPartialAuthorization,
} from '../../utils/session/selectors';
import NotificationsMethodsContainer from '../add-payment-method/components/NotificationMethods/NotificationsMethodsContainer';
import useComponentEntry from '../../hooks/useComponentEntry';
import PaymentMethodChannelSelector from '../components/PaymentMethodChannelSelector/PaymentMethodChannelSelector';
import paymentMethodEntryViewState from '../../utils/capabilities/paymentMethodEntryViewState';
import UnifiedPaymentFormEntry from '../UnifiedPaymentForm/UnifiedPaymentFormEntry';
import ExpressCheckoutWalletContainer from '../express-checkout-wallet/ExpressCheckoutWalletContainer';
import { getBackButtonHandler } from '../../utils/capabilities/back-button/getBackButtonHandler';
import getBackButtonDetails from '../../utils/capabilities/back-button/getBackButtonDetails';
import { getConfiguredChannelsForAgent } from '../../utils/session/getConfiguredChannelsForAgent';
import usePaymentMethodEntryLoader from '../../shared/hooks/payment-method-entry/usePaymentMethodEntryLoader';
import type { PaymentMethodType } from '../../services/customer/types';

import PayAndStoreCardContainer from './components/PayAndStoreCardContainer/PayAndStoreCardContainer';
import PayAndStoreAchContainer from './components/PayAndStoreAchContainer/PayAndStoreAchContainer';

const PayAndStoreEntry = () => {
  const { onCancel } = useContext(CancelCallbackContext);
  const { notify, closeNotification } = useContext(
    NotificationContext,
  );

  const {
    amount,
    authorizeCard,
    errorType,
    isChildStatusCanceled,
    localData,
    merchantTransactionId,
    paymentDescription,
    setApplicationData,
    setErrorType,
    setIsChildStatusCanceled,
    setShouldCancelChildSession,
    shouldCancelChildSession,
    statementDescriptorSuffix,
    vendorPlatformKey,
    originalCheckoutSessionResponse,
    setLocalData,
    isInvalidUser,
    showSaveFeatureBlock,
    isApplePayButtonEnabled,
    isGooglePayButtonEnabled,
    isAgentAssistedSession,
    isInitializing,
  } = useComponentEntry();

  const onSuccess = () => {
    setApplicationData({ isChildWaitingOnGlobalSession: true });
  };

  const onError = (message: string) => {
    notify(serviceErrorNotification({ message }));
  };

  const onBeforeSubmit = () => {
    closeNotification();
  };

  const onLoadManagedComponent = () => {
    setApplicationData({ overlayLoaderConfig: { show: true } });
  };

  const partialAuthorization = getPartialAuthorization(
    originalCheckoutSessionResponse,
  );

  const viewState = paymentMethodEntryViewState({
    errorType,
    componentEntryLocalData: localData,
    checkoutSessionDetails: originalCheckoutSessionResponse,
  });

  const backButtonHandler = getBackButtonHandler({
    backButtonDetails: getBackButtonDetails({
      componentEntryLocalData: localData,
      sessionDetails: originalCheckoutSessionResponse,
    }),
    closeNotification,
    componentEntryLocalData: localData,
    setComponentEntryLocalData: setLocalData,
  });

  const {
    isLoading,
    onAchFormReady,
    onCardFormReady,
    onManagedComponentReady,
    reset,
  } = usePaymentMethodEntryLoader(viewState, isInitializing);

  const renderPayAndStoreCardContainer = ({
    formTitle,
    selectedPaymentMethod,
    onBackClickLocal,
  }: {
    formTitle: string;
    selectedPaymentMethod: PaymentMethodType;
    onBackClickLocal?: () => void;
  }) => (
    <PayAndStoreCardContainer
      vendorPlatformKey={vendorPlatformKey}
      merchantTransactionId={merchantTransactionId}
      amount={amount}
      authorizeCard={authorizeCard}
      partialAuthorization={partialAuthorization}
      paymentDescription={paymentDescription}
      statementDescriptorSuffix={statementDescriptorSuffix}
      stripeApi={StripeApi}
      paymentApi={PaymentApi}
      showSaveFeatureBlock={showSaveFeatureBlock}
      googlePayButtonEnabled={isGooglePayButtonEnabled}
      applePayButtonEnabled={isApplePayButtonEnabled}
      formTitle={formTitle}
      isInFocus={selectedPaymentMethod === 'CARD'}
      onSuccess={onSuccess}
      onCancel={onCancel}
      onError={onError}
      onBeforeSubmit={onBeforeSubmit}
      onBackClick={onBackClickLocal}
      onLoad={onLoadManagedComponent}
      onLoadComplete={onCardFormReady}
    />
  );

  const handleErrorNotificationsMethods = useCallback(() => {
    setErrorType('error');
  }, [setErrorType]);

  useEffect(() => {
    if (isInitializing) {
      return;
    }

    reset();
  }, [viewState, isInitializing, reset]);

  if (isInitializing) {
    return null;
  }

  const renderPayAndStoreAchContainer = ({
    formTitle,
    selectedPaymentMethod,
    onBackClickLocal,
  }: {
    formTitle: string;
    selectedPaymentMethod: PaymentMethodType;
    onBackClickLocal?: () => void;
  }) => (
    <PayAndStoreAchContainer
      merchantTransactionId={merchantTransactionId}
      amount={amount}
      paymentDescription={paymentDescription}
      paymentApi={PaymentApi}
      showSaveFeatureBlock={showSaveFeatureBlock}
      applePayButtonEnabled={isApplePayButtonEnabled} // TODO: Need to add express checkout element
      googlePayButtonEnabled={isGooglePayButtonEnabled} // TODO: Need to add express checkout element
      formTitle={formTitle}
      isInFocus={selectedPaymentMethod === 'BANK_ACCOUNT'}
      onLoadComplete={onAchFormReady}
      onCancel={onCancel}
      onSuccess={onSuccess}
      onBeforeSubmit={onBeforeSubmit}
      onBackClick={onBackClickLocal}
    />
  );

  if (isInvalidUser) {
    return <ErrorCard />;
  }

  switch (viewState) {
    case 'cancel_child_session':
      return (
        <CancelChildSessionWrapper
          errorType={errorType!}
          isChildStatusCanceled={isChildStatusCanceled}
          onCancel={onCancel}
          originalCheckoutSessionResponse={
            originalCheckoutSessionResponse
          }
          selectedChannelType={localData.selectedChannelType}
          setErrorType={setErrorType}
          setIsChildStatusCanceled={setIsChildStatusCanceled}
          setShouldCancelChildSession={
            setShouldCancelChildSession
          }
          shouldCancelChildSession={shouldCancelChildSession}
          setLocalData={setLocalData}
          childSessionId={localData.childSessionId}
        />
      );
    case 'channel_selector':
      return (
        <PaymentMethodChannelSelector
          userJourney="MAKE_PAYMENT"
          paymentMethodChannels={
            getConfiguredChannelsForAgent(
              originalCheckoutSessionResponse,
            ) ?? []
          }
          onDone={(userSelectedChannel) => {
            setLocalData((prevState) => ({
              ...prevState,
              selectedChannelType: userSelectedChannel,
              isBackToChannelSelection: false,
            }));
          }}
          onCancel={onCancel}
          onLoadComplete={onManagedComponentReady}
        />
      );
    case 'awaiting_user_response':
      return (
        <AwaitingUserResponseContainer
          onCancel={() => {
            setShouldCancelChildSession(true);
            setErrorType('cancel');
          }}
          onError={(_errorType) => {
            setErrorType(_errorType);
          }}
          onDone={onSuccess}
          setIsChildStatusCanceled={setIsChildStatusCanceled}
          destination={localData.notificationDestination!}
          paymentMethodChannel={localData.selectedChannelType!}
          userJourney="MAKE_PAYMENT"
          onLoadComplete={onManagedComponentReady}
        />
      );
    case 'notifications_methods': {
      const defaultDestination =
        localData.selectedChannelType === 'TEXT'
          ? getPhoneNumber(originalCheckoutSessionResponse)
          : getEmailAddress(originalCheckoutSessionResponse);

      return (
        <NotificationsMethodsContainer
          userJourney="MAKE_PAYMENT"
          checkoutSessionId={getSessionId(
            originalCheckoutSessionResponse,
          )}
          paymentMethodChannel={localData.selectedChannelType!}
          destination={defaultDestination}
          onDone={({
            childSessionId,
            notificationDestination,
            notificationChannel,
          }) => {
            setLocalData((prevState) => ({
              ...prevState,
              childSessionId,
              notificationDestination,
              selectedChannelType: notificationChannel,
              isBackToChannelSelection: false,
            }));
          }}
          onError={handleErrorNotificationsMethods}
          onBackClick={backButtonHandler}
          onCancel={onCancel}
          onLoadComplete={onManagedComponentReady}
        />
      );
    }
    case 'unified_payment_method_form':
      return (
        <Box
          style={{
            display: isLoading ? 'none' : 'block',
          }}
        >
          <UnifiedPaymentFormEntry
            userJourney="MAKE_PAYMENT"
            onBackClick={backButtonHandler}
            backButtonLabel="Back"
            isAgentAssistedSession={isAgentAssistedSession}
            renderExpressCheckout={() => {
              return (
                <ExpressCheckoutWalletContainer
                  vendorPlatformKey={vendorPlatformKey}
                  merchantTransactionId={merchantTransactionId}
                  amount={amount}
                  paymentDescription={paymentDescription}
                  statementDescriptorSuffix={
                    statementDescriptorSuffix
                  }
                  authorizeCard={authorizeCard}
                  sectionDivider
                  stripeApi={StripeApi}
                  paymentApi={PaymentApi}
                  onBeforeSubmit={onBeforeSubmit}
                  onSuccess={onSuccess}
                  onError={onError}
                />
              );
            }}
          >
            {(selectedPaymentMethod) => {
              return (
                <>
                  <Box
                    sx={{
                      display:
                        selectedPaymentMethod === 'CARD'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {renderPayAndStoreCardContainer({
                      formTitle: '',
                      selectedPaymentMethod,
                    })}
                  </Box>
                  <Box
                    sx={{
                      display:
                        selectedPaymentMethod === 'BANK_ACCOUNT'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {renderPayAndStoreAchContainer({
                      formTitle: '',
                      selectedPaymentMethod,
                    })}
                  </Box>
                </>
              );
            }}
          </UnifiedPaymentFormEntry>
        </Box>
      );
    case 'view_state_error':
    default:
      return <ErrorCard />;
  }
};

export default PayAndStoreEntry;
