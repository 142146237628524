export type SycurioPCIResponseType = {
  nameOnCard: string;
  lastFour: string;
  expYear: string;
  expMonth: string;
  brand: string;
  zipOfCard: string;
  token: string;
};

export const getSycurioPCIResponse = (
  sycurioEventData: string,
): SycurioPCIResponseType | undefined => {
  try {
    /** TODO: need to remove these mock values when sycurio delivers
     *  their commitment date is by Nov. 30th, 2024
     */
    const {
      token,
      nameOnCard = 'Test User',
      lastFour,
      expYear = '2023',
      expMonth = '12',
      brand = 'VISA',
      zipOfCard = '12345',
    } = JSON.parse(sycurioEventData);
    return {
      token,
      nameOnCard,
      lastFour,
      expYear,
      expMonth,
      brand,
      zipOfCard,
    };
  } catch (error) {
    console.error('Error parsing Sycurio PCI response');
  }
  return undefined;
};
