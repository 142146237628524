import { useEffect } from 'react';

import commonSycurioTelephonicEntryService from '../../../services/commonCheckout/commonSycurioTelephonicEntryService';

export default function useStartSycurioTelephonicEntry({
  checkoutSessionId,
  startSpinner,
  onError,
}: {
  checkoutSessionId: string;
  startSpinner: () => void;
  onError: () => void;
}) {
  useEffect(() => {
    if (!checkoutSessionId) {
      return;
    }
    (async () => {
      startSpinner();
      try {
        await commonSycurioTelephonicEntryService({
          checkoutSessionId,
          eventType: 'startSycurio',
        });
      } catch (e) {
        console.error(
          'Error starting sycurio telephonic entry session',
        );
        onError();
      }
    })();
  }, [checkoutSessionId]);
}
