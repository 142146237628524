import type { CheckoutSessionsResponse } from '../../services/commonCheckout/types/CheckoutSessionsResponse';
import type {
  PaymentMethodChannelType,
  PaymentMethodChannels,
} from '../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import type { PaymentMethodConfig } from '../../services/commonCheckout/types/SessionConfig/PaymentMethodConfig';
import { isConsentTextAvailableAndValid } from '../capabilities/isConsentTextAvailableAndValid';

import {
  getAgent,
  getConfigMode,
  getMerchantConsentText,
} from './selectors';

function combineChannels({
  paymentMethod,
  session,
}: {
  paymentMethod: PaymentMethodConfig[];
  session: CheckoutSessionsResponse;
}): PaymentMethodChannels {
  const isPaymentMode = getConfigMode(session) === 'PAYMENT';

  const channelMap: Partial<
    Record<PaymentMethodChannelType, boolean>
  > = {};

  // First, process the "CARD" method channels
  const cardMethod = paymentMethod.find(
    (method) => method.type === 'CARD',
  );
  if (cardMethod) {
    cardMethod.channels.forEach((channel) => {
      const { type, enabled } = channel;
      channelMap[type] = enabled;
    });
  }

  // Next, process the "BANK_ACCOUNT" method channels with the consent check
  const bankAccountMethod = paymentMethod.find(
    (method) => method.type === 'BANK_ACCOUNT',
  );
  if (bankAccountMethod) {
    bankAccountMethod.channels.forEach((channel) => {
      const { type, enabled } = channel;

      // For "WEBFORM" in "BANK_ACCOUNT", ensure consent check only if not already enabled by "CARD"
      if (
        isPaymentMode &&
        type === 'WEBFORM' &&
        !isConsentTextAvailableAndValid(
          getMerchantConsentText(session),
        )
      ) {
        channelMap[type] = channelMap[type] || false;
      } else {
        channelMap[type] = channelMap[type] || enabled;
      }
    });
  }

  return Object.keys(channelMap).map((type, index) => ({
    type: type as PaymentMethodChannelType,
    enabled:
      channelMap[type as PaymentMethodChannelType] || false,
    order: index,
  }));
}

/**
 * @description: this utility function  will return all the notification channels
 * configured for an AGENT session.
 *
 * For AGENT sessions, the API does NOT include
 * express checkout elements including: GOOGLE_PAY &
 * APPLE_PAY.
 */
export const getConfiguredChannelsForAgent = (
  session: CheckoutSessionsResponse,
) => {
  const { config } = session.checkoutSession.checkoutRequest;
  const { paymentMethod } = config ?? {};
  const isNotAgentSession = !getAgent(session);
  if (isNotAgentSession || !config || !paymentMethod?.length) {
    return [];
  }

  if (paymentMethod.length === 1) {
    return paymentMethod[0].channels;
  }

  const sortedCombinedChannels = combineChannels({
    paymentMethod,
    session,
  });

  const orderArray: PaymentMethodChannelType[] = [
    'TEXT',
    'EMAIL',
    'WEBFORM',
    'TELEPHONIC_ENTRY',
  ];

  sortedCombinedChannels.sort((a, b) => {
    const aIndex = orderArray.indexOf(a.type);
    const bIndex = orderArray.indexOf(b.type);

    return aIndex - bIndex;
  });

  return sortedCombinedChannels;
};
