const fieldHasCardNumber = (value: string) => {
  const parsedValue = value.replace(/[\s-]/g, '');

  let result = false;

  const regex = /\d{13,}/;

  if (regex.test(parsedValue)) {
    result = true;
  }

  return result;
};

export default fieldHasCardNumber;
