import { logApiError } from './log-api-error';
import { logStripeError } from './log-stripe-error';
import { logSycurioError } from './log-sycurio-error';
import type { TelemetryError } from './types';

export const logError = ({
  errorType,
  error,
  metadata = {},
}: TelemetryError) => {
  if (errorType === 'api_error') {
    logApiError({ errorType, error, metadata });
  }
  if (errorType === 'stripe_error') {
    logStripeError({ errorType, error, metadata });
  }
  if (errorType === 'sycurio_error') {
    logSycurioError({ errorType, error, metadata });
  }
};
