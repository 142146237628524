import {
  getAgent,
  getConfigMode,
} from '../../session/selectors';
import isPrimarySecondaryContainer from '../../container-config/isPrimarySecondaryContainer';
import type PaymentMethodEntryViewStates from '../../../shared/types/capabilities/PaymentMethodEntryViewStates';
import type { CheckoutSessionsResponse } from '../../../services/commonCheckout/types/CheckoutSessionsResponse';
import type { ContainerConfig } from '../../../components/container-types/types';

import hasMultipleChannels from './utils/hasMultipleChannels';

const getBackButtonVisibilityState = ({
  viewState,
  sessionDetails,
  containerConfig,
}: {
  viewState: PaymentMethodEntryViewStates;
  sessionDetails: CheckoutSessionsResponse;
  containerConfig?: ContainerConfig;
}) => {
  const configMode = getConfigMode(sessionDetails);
  const isWalletMode = ['WALLET'].includes(configMode);
  const isAgent = !!getAgent(sessionDetails);

  // Visibility logic applies only to primary-secondary container or wallet mode;
  // Otherwise, 'back button' is visible by default
  if (
    !containerConfig ||
    !isPrimarySecondaryContainer(containerConfig) ||
    !isWalletMode
  ) {
    return true;
  }

  // !isAgent: true || (isAgent: false && !hasMultipleChannels: false): false = !true = false
  // !isAgent: false || (isAgent: true && !hasMultipleChannels: false): false = !false = true
  // !isAgent: false || (isAgent: true && !hasMultipleChannels: true): true = !true = false
  switch (viewState) {
    case 'unified_payment_method_form':
    case 'sycurio_telephonic_entry':
    case 'sycurio_phone_entry_submit_page':
    case 'notifications_methods': {
      return !(
        !isAgent ||
        (isAgent && !hasMultipleChannels(sessionDetails))
      );
    }
    case 'channel_selector':
      return false;
    default: {
      return true;
    }
  }
};

export default getBackButtonVisibilityState;
