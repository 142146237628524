import React, { useContext, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { AppContext } from '../../capabilities/contextStore/AppContext';
import Title from '../../ui/Title/Title';
import { ICON_IDS } from '../../utils/element-ids';

export interface ErrorCardProps {
  title?: string;
  description?: string;
  icon?: 'error' | 'cancel' | 'timeout' | 'sycurioError';
  /**
   * Flag to hide the description. By default, the description will
   * displayed to maintain the existing functionality. However, if there is a need
   * to hide it, this flag can be set to `false`.
   */
  showDescription?: boolean;
}

const ErrorCard = ({
  title = 'Something went wrong.',
  description = 'Please try again.',
  icon = 'error',
  showDescription = true,
}: ErrorCardProps) => {
  const { setData } = useContext(AppContext);
  useEffect(() => {
    setData({ overlayLoaderConfig: { show: false } });
  }, []);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ gap: '32px' }}
    >
      {icon === 'error' ? (
        <ErrorIcon
          data-testid={ICON_IDS.SESSION_OUTCOME_ERROR_ICON}
          style={{
            color: '#C40000',
            fontSize: '60px',
            marginTop: '60px',
          }}
        />
      ) : null}

      {icon === 'cancel' ? (
        <CancelIcon
          data-testid={ICON_IDS.SESSION_OUTCOME_CANCEL_ICON}
          style={{
            color: '#002677',
            fontSize: '60px',
            marginTop: '60px',
          }}
        />
      ) : null}

      {icon === 'timeout' ? (
        <AccessTimeIcon
          data-testid={ICON_IDS.SESSION_OUTCOME_TIMEOUT_ICON}
          style={{
            color: '#C24E14',
            fontSize: '60px',
            marginTop: '60px',
          }}
        />
      ) : null}

      <Box>
        <Grid
          item
          container
          direction="column"
          style={{ gap: '8px', wordBreak: 'break-word' }}
        >
          <Title
            title={
              // Per discussion with Phil 4:23PM CST 7/17/2023,
              // We're embeddeding all description subtext inside the title.
              // This is because we can receive server errors like "Payment could not be authorized. Please try a different payment method."
              // the server doesn't provide any indication what is subtext and what is not,
              // and we want to maintain consistency across dead-end error screens.
              // (We agreed success screens will continue to show subtext)
              `${title} ${showDescription ? description : ''}`
            }
            textAlign="center"
          />
          {/* {showDescription ? (
            <Typography align="center">{description}</Typography>
          ) : null} */}
        </Grid>
      </Box>
    </Grid>
  );
};

export default ErrorCard;
