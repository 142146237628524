import { useEffect } from 'react';

import type { SycurioErrorEventType } from '../sycurio-telephonic-entry/SycurioTelephonicEntry';

type SycurioAgentPageObserverProps = {
  hasCasiSessionFailed: boolean;
  hasSycurioIframeFailedToLoad: boolean;
  hasTimeElapseForSycurioInitMessage: boolean;
  stopSpinner: () => void;
  onError: (
    errorEvent: Pick<SycurioErrorEventType, 'sycurioErrorType'>,
  ) => void;
};

export default function useSycurioAgentPageObserver({
  hasCasiSessionFailed,
  hasSycurioIframeFailedToLoad,
  hasTimeElapseForSycurioInitMessage,
  stopSpinner,
  onError,
}: SycurioAgentPageObserverProps) {
  useEffect(() => {
    if (
      !hasTimeElapseForSycurioInitMessage &&
      !hasCasiSessionFailed &&
      !hasSycurioIframeFailedToLoad
    ) {
      return;
    }

    if (hasCasiSessionFailed) {
      onError({ sycurioErrorType: 'casiError' });
    }

    if (hasSycurioIframeFailedToLoad) {
      onError({
        sycurioErrorType: 'sycurioIframeError',
      });
    }

    stopSpinner();
  }, [
    hasCasiSessionFailed,
    hasSycurioIframeFailedToLoad,
    hasTimeElapseForSycurioInitMessage,
  ]);
}
