import { Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { CANCEL_BUTTON } from '../../../shared/strings';
import BackButton from '../../../ui/BackButton/BackButton';
import PageTitle from '../../../ui/PageTitle/PageTitle';
import Button from '../../../ui/Button/Button';
import type ComponentEntryLocalData from '../../../shared/types/component-entry/ComponentEntryLocalData';

const SycurioPhoneEntrySubmitView = ({
  localData,
  backButtonHandler,
  onCancel,
  onSuccess,
}: {
  localData: ComponentEntryLocalData;
  backButtonHandler?: () => void;
  onCancel?: () => void;
  onSuccess: (event: { paymentMethodId: string }) => void;
}) => {
  return (
    <Box>
      {backButtonHandler ? (
        <BackButton
          label="Back"
          onClick={() => {
            backButtonHandler?.();
          }}
        />
      ) : null}
      <PageTitle
        title="Add via Phone Entry"
        subTitle="Add a payment method using phone entry"
      />
      <Typography variant="body1">
        Phone Entry Submit Page (Stub Component):
      </Typography>
      <Typography variant="body1">
        Token: {localData.paymentMethodId}
      </Typography>
      <Typography variant="body1">
        brand: {localData.telephonicEntry?.brand}
      </Typography>
      <Typography variant="body1">
        nameOnCard: {localData.telephonicEntry?.nameOnCard}
      </Typography>
      <Typography variant="body1">
        lastFour: {localData.telephonicEntry?.lastFour}
      </Typography>
      <Typography variant="body1">
        expMonth: {localData.telephonicEntry?.expMonth}
      </Typography>
      <Typography variant="body1">
        expYear: {localData.telephonicEntry?.expYear}
      </Typography>
      <Typography variant="body1">
        zipofCard: {localData.telephonicEntry?.zipOfCard}
      </Typography>

      <Grid
        container
        direction="column"
        sx={{ gap: '16px', marginTop: '24px' }}
        position="sticky"
        display="absolute"
      >
        <Button
          onClick={() => {
            onSuccess({ paymentMethodId: 'stub' });
          }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
        <Button
          isLoading={false}
          onClick={() => {
            onCancel?.();
          }}
          variant="contained"
          color="secondary"
        >
          {CANCEL_BUTTON}
        </Button>
      </Grid>
    </Box>
  );
};

export default SycurioPhoneEntrySubmitView;
