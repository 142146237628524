import React, { useEffect, useContext, useState } from 'react';
import type { FC } from 'react';

import { AppContext } from '../../contextStore/AppContext';
import {
  CONTAINER_IDS,
  TRANSLATION_CLASS_NAMES,
} from '../../../utils/element-ids';
import useEnvironmentContextProvider from '../../contextStore/EnvironmentContext/useEnvironmentContextProvider';

import useRenderedLanguage from './utils/useRenderedLanguage';

declare global {
  interface Window {
    OneLinkMoxieJS: any;
  }
}

type LanguageSelectorProps = {
  children?: React.ReactNode;
};

const LanguageSelector: FC<LanguageSelectorProps> = ({
  children,
}) => {
  const { setData } = useContext(AppContext);
  const { appEnvironmentType } = useEnvironmentContextProvider();

  const { renderedLanguage, translationKey } =
    useRenderedLanguage();

  const [moxieTranslated, setMoxieTranslated] = useState<
    string | null
  >(null);

  const updateMoxieTranslatedValue = () => {
    const metaTag = document.querySelector(
      "meta[name='moxietranslated']",
    );
    const newMoxieTranslatedValue = metaTag
      ? metaTag.getAttribute('content')
      : null;

    // Only update state if the value has changed
    if (newMoxieTranslatedValue !== moxieTranslated) {
      setMoxieTranslated(newMoxieTranslatedValue);
    }
  };

  useEffect(() => {
    if (moxieTranslated) {
      if (appEnvironmentType === 'embedded') {
        const matchingElementInEmbedded = document.querySelector(
          `#${CONTAINER_IDS.CHECKOUT_CONTAINER_ID}`,
        );
        if (matchingElementInEmbedded) {
          matchingElementInEmbedded.classList.add(
            TRANSLATION_CLASS_NAMES.TRANSLATION_CLASS_NAME,
          );
        }
      } else if (appEnvironmentType === 'hosted') {
        const matchingElementInHosted = document.querySelector(
          '#ccg-hosted-root',
        );
        if (matchingElementInHosted) {
          matchingElementInHosted.classList.add(
            TRANSLATION_CLASS_NAMES.TRANSLATION_CLASS_NAME,
          );
        }
      }
    }
  }, [moxieTranslated]);

  useEffect(() => {
    let observer: MutationObserver | null = null;
    if (
      renderedLanguage &&
      renderedLanguage !== 'en' &&
      translationKey
    ) {
      setData({ overlayLoaderConfig: { show: true } });
      const tKey =
        renderedLanguage === 'es' ? translationKey : '';
      const oneLinkScript = document.createElement('script');
      oneLinkScript.src =
        'https://www.onelink-edge.com/moxie.min.js';
      oneLinkScript.setAttribute('data-oljs', tKey);
      oneLinkScript.type = 'text/javascript';
      oneLinkScript.referrerPolicy =
        'no-referrer-when-downgrade';
      document.body.appendChild(oneLinkScript);

      // Set up a MutationObserver to observe changes to the <head> element
      observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          // Check if the mutations include changes to `moxietranslated`
          if (
            mutation.type === 'childList' ||
            mutation.type === 'attributes'
          ) {
            updateMoxieTranslatedValue();
          }
        });
      });

      // Start observing
      observer.observe(document.head, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['content'],
      });

      // Initial check for the meta tag
      updateMoxieTranslatedValue();
    }

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      if (renderedLanguage !== 'en' && observer) {
        observer.disconnect();
      }
    };
  }, [renderedLanguage, translationKey]);
  return (
    <>
      {renderedLanguage === 'en' || moxieTranslated
        ? children
        : null}
    </>
  );
};

export default LanguageSelector;
