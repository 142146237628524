import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React from 'react';
import type { FC } from 'react';

import { logError } from '../../services/AppInsights/Error/log-error';

import { getExpressCheckoutButtonVisibility } from './utils/getExpressCheckoutButtonVisibility';
import { getPaymentType } from './utils/getPaymentType';
import type { ExpressCheckoutWalletType } from './types/ExpressCheckoutWalletType';
import type { ExpressCheckoutWalletProps } from './types/ExpressCheckoutWalletProps';

/** @note: this would be the fallback as suggested by Phil Hartmann - pls see PR for
 *  record of the conversation
 */
const ExpressCheckoutWallet: FC<ExpressCheckoutWalletProps> = ({
  googlePayButtonEnabled = false,
  applePayButtonEnabled = false,
  merchantName = 'merchant',
  onLoadComplete,
  onSubmit,
  styleConfig,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const handleOnSubmit = ({
    cardChannel,
    name,
  }: {
    cardChannel: ExpressCheckoutWalletType;
    name: string;
  }) => {
    if (!elements || !stripe) {
      return;
    }

    onSubmit({
      elements,
      stripe,
      formData: {
        nameOnCard: name,
        default: false,
        manufacturerCard: false,
        nickname: '',
        savePaymentMethod: false,
        cardChannel,
      },
    });
  };

  return (
    <ExpressCheckoutElement
      onReady={(event) => {
        onLoadComplete?.({
          googlePayAvailable:
            event.availablePaymentMethods?.googlePay ?? false,
          applePayAvailable:
            event.availablePaymentMethods?.applePay ?? false,
        });
      }}
      onClick={({ resolve }) => {
        resolve({
          business: {
            name: merchantName,
          },
        });
      }}
      onConfirm={(event) => {
        handleOnSubmit({
          cardChannel: getPaymentType(event.expressPaymentType),
          name:
            event.billingDetails?.name ?? 'NAME_NOT_AVAILABLE',
        });
      }}
      options={{
        buttonHeight: styleConfig.buttonHeight,
        wallets: {
          applePay: getExpressCheckoutButtonVisibility(
            applePayButtonEnabled,
          ),
          googlePay: getExpressCheckoutButtonVisibility(
            googlePayButtonEnabled,
          ),
        },
        buttonType: { googlePay: 'plain', applePay: 'plain' },
      }}
      onLoadError={(event) => {
        const { type, code, message } = event.error;
        const { elementType } = event;
        logError({
          errorType: 'stripe_error',
          error: new Error("Error loading ExpressCheckoutElement"),
          metadata: {
            elementType,
            type,
            code,
            message,
          },
        });
      }}
    />
  );
};

export default ExpressCheckoutWallet;
