import React, { useContext, useEffect } from 'react';

import { AppContext } from '../../capabilities/contextStore/AppContext';
import { CONTAINER_IDS } from '../../utils/element-ids';
import useEnvironmentContextProvider from '../../capabilities/contextStore/EnvironmentContext/useEnvironmentContextProvider';

import OverlayLoader from './OverlayLoader';

const ConditionalOverlayLoader = () => {
  const { overlayLoaderConfig } = useContext(AppContext);
  const { appEnvironmentType, containerConfig } =
    useEnvironmentContextProvider();

  useEffect(() => {
    if (
      appEnvironmentType === 'embedded' &&
      containerConfig?.type === 'inline' &&
      containerConfig.autoScrollEnabled
    ) {
      const matchingElementInEmbedded = document.querySelector(
        `#${CONTAINER_IDS.CHECKOUT_CONTAINER_ID}`,
      );
      if (matchingElementInEmbedded) {
        setTimeout(() => {
          matchingElementInEmbedded.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 100);
      }
    }
  }, [overlayLoaderConfig.show]);

  return overlayLoaderConfig.show ? (
    <OverlayLoader
      description={overlayLoaderConfig.description}
    />
  ) : null;
};

export default ConditionalOverlayLoader;
