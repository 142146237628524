/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Box } from '@mui/material';

import {
  getEmailAddress,
  getPhoneNumber,
} from '../../utils/session/selectors';
import useComponentEntry from '../../hooks/useComponentEntry';
import CancelChildSessionWrapper from '../../shared/CancelChildSessionWrapper/CancelChildSessionWrapper';
import PaymentMethodChannelSelector from '../components/PaymentMethodChannelSelector/PaymentMethodChannelSelector';
import AwaitingUserResponseContainer from '../components/AwaitingUserResponse/AwaitingUserResponseContainer';
import UnifiedPaymentFormEntry from '../UnifiedPaymentForm/UnifiedPaymentFormEntry';
import paymentMethodEntryViewState from '../../utils/capabilities/paymentMethodEntryViewState';
import { getBackButtonHandler } from '../../utils/capabilities/back-button/getBackButtonHandler';
import getBackButtonDetails from '../../utils/capabilities/back-button/getBackButtonDetails';
import { NotificationContext } from '../contextStore/NotificationContext';
import { getConfiguredChannelsForAgent } from '../../utils/session/getConfiguredChannelsForAgent';
import usePaymentMethodEntryLoader from '../../shared/hooks/payment-method-entry/usePaymentMethodEntryLoader';
import useEnvironmentContextProvider from '../contextStore/EnvironmentContext/useEnvironmentContextProvider';
import SycurioTelephonicEntry from '../sycurio-pci-forms/sycurio-telephonic-entry/SycurioTelephonicEntry';
import commonSycurioTelephonicEntryService from '../../services/commonCheckout/commonSycurioTelephonicEntryService';
import SycurioPhoneEntrySubmitView from '../sycurio-pci-forms/sycurio-phone-entry-submit-view/SycurioPhoneEntrySubmitView';
import { logError } from '../../services/AppInsights/Error/log-error';

import type { AddPaymentMethodBaseProps } from './types/AddPaymentMethodBaseProps';
import AddPaymentMethodContainer from './AddPaymentMethodContainer';
import NotificationsMethodsContainer from './components/NotificationMethods/NotificationsMethodsContainer';
import AddPaymentMethodAchContainer from './components/AddPaymentMethodAchContainer/AddPaymentMethodAchContainer';
import AddPaymentMethodEntryErrorHandler from './components/AddPaymentMethodEntryErrorHandler/AddPaymentMethodEntryErrorHandler';

const AddPaymentMethodEntry = ({
  customerApi,
  customerId,
  onCancel,
  onSuccess,
  stripeApi,
  vendorPlatformKey,
  onBackClick,
  onBeforeSubmit,
  onError,
}: AddPaymentMethodBaseProps) => {
  const {
    localData,
    originalCheckoutSessionResponse,
    setLocalData,
    setShouldCancelChildSession,
    setIsChildStatusCanceled,
    isChildStatusCanceled,
    shouldCancelChildSession,
    errorType,
    setErrorType,
    isAgentAssistedSession,
    isInitializing,
    setApplicationData,
    checkoutSessionId,
  } = useComponentEntry();
  const telephonicEntryFailedAttemps = useRef<number>(0);

  const { closeNotification, notify } = useContext(
    NotificationContext,
  );

  const { containerConfig } = useEnvironmentContextProvider();

  const viewState = paymentMethodEntryViewState({
    errorType,
    componentEntryLocalData: localData,
    checkoutSessionDetails: originalCheckoutSessionResponse,
  });

  const paymentMethodChannels =
    getConfiguredChannelsForAgent(
      originalCheckoutSessionResponse,
    ) ?? [];

  const backButtonHandler = getBackButtonHandler({
    backButtonDetails: getBackButtonDetails({
      errorType,
      componentEntryLocalData: localData,
      sessionDetails: originalCheckoutSessionResponse,
      containerConfig,
    }),
    closeNotification,
    componentEntryLocalData: localData,
    onBackButtonParentHandler: onBackClick,
    setComponentEntryLocalData: setLocalData,
    clearError: () => {
      setErrorType(undefined);
    },
    shouldTriggerSycurioDeleteCall:
      paymentMethodChannels.length === 1 &&
      isAgentAssistedSession &&
      viewState === 'sycurio_telephonic_entry',
    isSycurioPhoneEntryForm:
      viewState === 'sycurio_telephonic_entry',
  });

  const {
    isLoading,
    onAchFormReady,
    onCardFormReady,
    onManagedComponentReady,
    reset,
  } = usePaymentMethodEntryLoader(viewState, isInitializing);

  const renderAddPaymentMethodCardContainer = ({
    formTitle,
    onBackClickLocal,
    selectedPaymentMethod,
  }: {
    formTitle: string;
    onBackClickLocal?: () => void;
    selectedPaymentMethod: string;
  }) => (
    <AddPaymentMethodContainer
      customerApi={customerApi}
      vendorPlatformKey={vendorPlatformKey}
      formTitle={formTitle}
      stripeApi={stripeApi}
      customerId={customerId}
      onSuccess={onSuccess}
      onBeforeSubmit={onBeforeSubmit}
      onBackClick={onBackClickLocal}
      onCancel={onCancel}
      onLoadComplete={onCardFormReady}
      onError={onError}
      isInFocus={selectedPaymentMethod === 'CARD'}
    />
  );

  const renderAddPaymentMethodAchContainer = ({
    formTitle,
    onBackClickLocal,
    selectedPaymentMethod,
  }: {
    formTitle: string;
    onBackClickLocal?: () => void;
    selectedPaymentMethod: string;
  }) => (
    <AddPaymentMethodAchContainer
      customerId={customerId}
      onCancel={onCancel}
      customerApi={customerApi}
      onSuccess={onSuccess}
      formTitle={formTitle}
      onBackClick={onBackClickLocal}
      onBeforeSubmit={onBeforeSubmit}
      onLoadComplete={onAchFormReady}
      onError={onError}
      isInFocus={selectedPaymentMethod === 'BANK_ACCOUNT'}
    />
  );

  const handleErrorNotificationsMethods = useCallback(() => {
    setErrorType('error');
  }, [setErrorType]);

  useEffect(() => {
    if (isInitializing) {
      return;
    }

    reset();
  }, [viewState, isInitializing, reset]);

  if (isInitializing) {
    return null;
  }

  switch (viewState) {
    case 'cancel_child_session':
      return (
        <CancelChildSessionWrapper
          errorType={errorType!}
          isChildStatusCanceled={isChildStatusCanceled}
          onCancel={onCancel}
          originalCheckoutSessionResponse={
            originalCheckoutSessionResponse
          }
          setErrorType={setErrorType}
          setIsChildStatusCanceled={setIsChildStatusCanceled}
          setShouldCancelChildSession={
            setShouldCancelChildSession
          }
          shouldCancelChildSession={shouldCancelChildSession}
          setLocalData={setLocalData}
          childSessionId={localData.childSessionId}
          selectedChannelType={localData.selectedChannelType}
        />
      );
    case 'channel_selector':
      return (
        <PaymentMethodChannelSelector
          userJourney="ADD_PAYMENT_METHOD"
          hasTelephonicEntryFailedAttempsExceeded={
            telephonicEntryFailedAttemps.current === 5
          }
          paymentMethodChannels={paymentMethodChannels}
          onDone={(userSelectedChannel) => {
            setLocalData((prevState) => ({
              ...prevState,
              selectedChannelType: userSelectedChannel,
              isBackToChannelSelection: false,
            }));
          }}
          onCancel={onCancel}
          onBackClick={backButtonHandler}
          onLoadComplete={onManagedComponentReady}
        />
      );
    case 'awaiting_user_response':
      return (
        <AwaitingUserResponseContainer
          onCancel={() => {
            setShouldCancelChildSession(true);
            setErrorType('cancel');
          }}
          onError={(_errorType) => {
            setErrorType(_errorType);
          }}
          onDone={({ childSessionId, paymentMethodId }) => {
            setLocalData((prevState) => ({
              ...prevState,
              childSessionHistory:
                prevState.childSessionHistory && childSessionId
                  ? [
                      ...new Set([
                        ...prevState.childSessionHistory,
                        childSessionId,
                      ]),
                    ]
                  : [],
            }));
            onSuccess({ paymentMethodId });
          }}
          setIsChildStatusCanceled={setIsChildStatusCanceled}
          destination={localData.notificationDestination!}
          paymentMethodChannel={localData.selectedChannelType!}
          userJourney="ADD_PAYMENT_METHOD"
          onLoadComplete={onManagedComponentReady}
          childSessionHistory={
            localData.childSessionHistory ?? []
          }
        />
      );
    case 'notifications_methods': {
      const defaultDestination =
        localData.selectedChannelType === 'TEXT'
          ? getPhoneNumber(originalCheckoutSessionResponse)
          : getEmailAddress(originalCheckoutSessionResponse);

      return (
        <NotificationsMethodsContainer
          userJourney="ADD_PAYMENT_METHOD"
          checkoutSessionId={checkoutSessionId}
          paymentMethodChannel={localData.selectedChannelType!}
          destination={defaultDestination}
          onDone={({
            childSessionId,
            notificationDestination,
            notificationChannel,
          }) => {
            setLocalData((prevState) => ({
              ...prevState,
              childSessionId,
              notificationDestination,
              selectedChannelType: notificationChannel,
              isBackToChannelSelection: false,
            }));
          }}
          onError={handleErrorNotificationsMethods}
          onBackClick={backButtonHandler}
          onCancel={onCancel}
          onLoadComplete={onManagedComponentReady}
        />
      );
    }
    case 'unified_payment_method_form': {
      return (
        <Box
          style={{
            display: isLoading ? 'none' : 'block',
          }}
        >
          <UnifiedPaymentFormEntry
            userJourney="ADD_PAYMENT_METHOD"
            backButtonLabel="Back"
            onBackClick={backButtonHandler}
            isAgentAssistedSession={isAgentAssistedSession}
          >
            {(selectedPaymentMethod) => {
              return (
                <>
                  <Box
                    sx={{
                      display:
                        selectedPaymentMethod === 'CARD'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {renderAddPaymentMethodCardContainer({
                      formTitle: '',
                      selectedPaymentMethod,
                    })}
                  </Box>
                  <Box
                    sx={{
                      display:
                        selectedPaymentMethod === 'BANK_ACCOUNT'
                          ? 'block'
                          : 'none',
                    }}
                  >
                    {renderAddPaymentMethodAchContainer({
                      formTitle: '',
                      selectedPaymentMethod,
                    })}
                  </Box>
                </>
              );
            }}
          </UnifiedPaymentFormEntry>
        </Box>
      );
    }
    case 'sycurio_telephonic_entry': {
      /** todo: need to add container here to encapsulate all the logic related to phone entry */
      return (
        <SycurioTelephonicEntry
          originalCheckoutSessionResponse={
            originalCheckoutSessionResponse
          }
          userJourney="ADD_PAYMENT_METHOD"
          backButtonLabel="Back"
          onBackClick={backButtonHandler}
          onCancel={() => {
            commonSycurioTelephonicEntryService({
              eventType: 'onCancel',
              checkoutSessionId,
              method: 'DELETE',
            }).catch(() => ({}));
            onCancel();
          }}
          onDone={({
            sycurioPCIResponse: {
              token,
              ...restOfSycurioPCIResponse
            },
          }) => {
            /** TODO: when we move the SycurioTelephonicEntry to a container
             *  the call to save button on sycurio_phone_entry_submit_page will
             *  need to handle wallet scenarios by calling onSuccess. The typical
             *  flow for this screen is sycurio_phone_entry_submit_page always
             *  follows to provide user a chance to review the phone entry.
             */
            commonSycurioTelephonicEntryService({
              eventType: 'onDone',
              checkoutSessionId,
              method: 'DELETE',
            }).catch(() => ({}));
            setLocalData((prevState) => ({
              ...prevState,
              paymentMethodId: token,
              telephonicEntry: {
                hasCapturedSycurioPhoneEntry: true,
                ...restOfSycurioPCIResponse,
              },
            }));
          }}
          onError={({ sycurioErrorType, message }) => {
            const sycurioErrorPropsReset = {
              selectedChannelType: undefined,
              paymentMethodId: undefined,
              telephonicEntry: undefined,
            };
            telephonicEntryFailedAttemps.current += 1;
            setApplicationData({
              overlayLoaderConfig: { show: false },
            });
            if (sycurioErrorType === 'sycurioIframeError') {
              commonSycurioTelephonicEntryService({
                eventType: 'onError',
                checkoutSessionId,
                method: 'DELETE',
              }).catch(() => ({}));
              setLocalData((prevState) => ({
                ...prevState,
                ...sycurioErrorPropsReset,
                isBackToChannelSelection: false,
              }));
              logError({
                error: new Error(
                  'Failed to load iframe of sycurio fragment',
                ),
                errorType: 'sycurio_error',
                metadata: {
                  message,
                  code: sycurioErrorType,
                },
              });
              setApplicationData({
                overlayLoaderConfig: { show: false },
              });
              setErrorType('sycurioError');
            } else {
              const exceededAttemptMessageToNotify =
                "You're attempt to add payment method via phone entry have exceeded. Please try another channel.";
              setLocalData((prevState) => ({
                ...prevState,
                ...sycurioErrorPropsReset,
                isBackToChannelSelection: true,
              }));
              notify({
                severity: 'error',
                title:
                  telephonicEntryFailedAttemps.current === 5
                    ? exceededAttemptMessageToNotify
                    : message,
              });
            }
          }}
          controlSpinner={({ isLoaderOn }) => {
            setApplicationData({
              overlayLoaderConfig: { show: isLoaderOn },
            });
          }}
        />
      );
    }
    case 'sycurio_phone_entry_submit_page': {
      /** TODO:
       *  1. Need to handle wallet scenarios by calling onSuccess
       *  2. Need to handle scenarios where only channel available is telephonic-entry, and
       *     multiple channel (goes back to channel selector) for cancel button
       *  3. Need to handle onSuccess for wallet scenarios here
       *  4. Need to handle error scenarios for wallet scenarios here (ex.: retries exhausted)
       *  5. Check with Phil if cancel will bring user back to sycurio_telephonic_entry
       *
       *  Note: The typical flow for this screen is sycurio_phone_entry_submit_page always
       *  follows to provide user a chance to review the phone entry. Clicking back button
       *  should always take user back to sycurio_telephonic_entry.
       */
      return (
        <SycurioPhoneEntrySubmitView
          localData={localData}
          onCancel={onCancel}
          onSuccess={onSuccess}
          backButtonHandler={backButtonHandler}
        />
      );
    }
    case 'view_state_error':
    default:
      return (
        <AddPaymentMethodEntryErrorHandler
          onBackClick={backButtonHandler}
        />
      );
  }
};

export default AddPaymentMethodEntry;
