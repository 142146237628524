import { Button, Grid } from '@mui/material';
import React from 'react';

import ErrorCard from '../../../../components/ErrorCard/ErrorCard';
import { TEXT_CONTENT_IDS } from '../../../../utils/element-ids';

type ErrorBoundaryProps = {
  errorType: 'error' | 'cancel' | 'sycurioError';
  retryButtonText: string;
  isChildStatusCanceled: boolean;
  isChildSessionExpired: boolean;
  onCancel?:
    | (() => void)
    | ((args: { checkoutSessionId: string }) => void);
  onRetry: () => void;
  onTryAnotherMethod: () => void;
};

const ErrorBoundary = ({
  errorType,
  onCancel,
  retryButtonText,
  onRetry,
  onTryAnotherMethod,
  isChildStatusCanceled,
  isChildSessionExpired,
}: ErrorBoundaryProps) => {
  const isDescriptionShown = errorType !== 'cancel';
  // eslint-disable-next-line no-nested-ternary
  const statusMessage = isChildStatusCanceled
    ? 'Customer session has been canceled'
    : isChildSessionExpired
    ? 'Customer session has expired'
    : 'Customer session is no longer valid';

  const errorIconType = isChildSessionExpired
    ? 'timeout'
    : errorType;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ gap: '32px' }}
    >
      <ErrorCard
        icon={errorIconType}
        title={
          errorType === 'cancel'
            ? statusMessage
            : // TODO: Probably could remove this: matches ErrorCard default `title` prop
              'Something went wrong.'
        }
        description={
          errorType === 'cancel'
            ? ''
            : // TODO: Probably could remove this: matches ErrorCard default `description` prop
              'Please try again.'
        }
        showDescription={isDescriptionShown}
      />

      <Grid
        item
        container
        style={{ gap: '16px' }}
      >
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={onRetry}
          data-testid={
            TEXT_CONTENT_IDS.CHILD_SESSION_NO_LONGER_VALID_RESEND
          }
        >
          {retryButtonText}
        </Button>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={onTryAnotherMethod}
          data-testid={
            TEXT_CONTENT_IDS.CHILD_SESSION_NO_LONGER_VALID_TRY_ANOTHER
          }
        >
          Try another method
        </Button>
        <Button
          fullWidth
          color="secondary"
          sx={{
            color: '#0C55B8',
            border: 'none',
            '&:hover': {
              border: 'none',
              color: '#0C55B8',
              backgroundColor: '#4b4d4f0a',
            },
            '&:focus': {
              border: 'none',
              outlineColor: 'transparent',
              backgroundColor: '#4b4d4f0a',
            },
            '&:active': {
              backgroundColor: '#4b4d4f0a',
              color: '#0C55B8',
            },
          }}
          onClick={() =>
            onCancel?.({
              checkoutSessionId: 'xxxx-xxxx-xxxx-xxxx',
            })
          }
          data-testid={
            TEXT_CONTENT_IDS.CHILD_SESSION_NO_LONGER_VALID_CANCEL
          }
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default ErrorBoundary;
