import React, { useCallback, useEffect } from 'react';
import type { FC } from 'react';
import { Button, Box } from '@mui/material';

import type {
  PaymentMethodChannels,
  PaymentMethodChannelType,
} from '../../../services/commonCheckout/types/SessionConfig/PaymentMethodChannel';
import {
  TEXT_CONTENT_IDS,
  TITLE_IDS,
} from '../../../utils/element-ids';
import BackButton from '../../../ui/BackButton/BackButton';
import type { UserJourney } from '../../../shared/types/UserJourney';
import getPaymentMethodChannelSelectorTitle from '../../../utils/capabilities/getPaymentMethodChannelSelectorTitle';
import getPaymentMethodChannelSelectorSubTitle from '../../../utils/capabilities/getPaymentMethodChannelSelectorSubTitle';
import noop from '../../../utils/function/noop';
import PaymentMethodChannelConfig from '../../add-payment-method/constants/PaymentMethodChannelConfig';
import PageTitle from '../../../ui/PageTitle/PageTitle';
import useFormConfigManager from '../../../hooks/useFormConfigManager/useFormConfigManager';

import PaymentMethodChannelOption from './PaymentMethodChannelOption/PaymentMethodChannelOption';
import type { PaymentMethodChannelOptionProps } from './PaymentMethodChannelOption/PaymentMethodChannelOptionProps';

export type PaymentMethodChannelSelectorProps = {
  userJourney: UserJourney;
  paymentMethodChannels: PaymentMethodChannels;
  onDone: (selectedChannel: PaymentMethodChannelType) => void;
  onCancel: () => void;
  onBackClick?: () => void;
  onLoadComplete?: () => void;
  showBackButton?: boolean;
  hasTelephonicEntryFailedAttempsExceeded?: boolean;
};

type PaymentMethodChannelConfigType = Pick<
  PaymentMethodChannelOptionProps,
  'title' | 'type' | 'icon' | 'enabled'
>[];

const PaymentMethodChannelSelector: FC<
  PaymentMethodChannelSelectorProps
> = ({
  userJourney,
  paymentMethodChannels,
  hasTelephonicEntryFailedAttempsExceeded,
  onDone,
  onCancel,
  onBackClick,
  onLoadComplete = noop,
  showBackButton = true,
}) => {
  const { title, showActionsOnForm } = useFormConfigManager({
    title: getPaymentMethodChannelSelectorTitle(userJourney),
    titleTestId: TITLE_IDS.PAYMENT_METHOD_CHANNEL_SELECTOR_TITLE,
    backActionConfig: {
      label: 'Back',
      handler: onBackClick,
      testId:
        TEXT_CONTENT_IDS.PAYMENT_METHOD_CHANNEL_SELECTOR_BACK_BUTTON,
    },
    secondaryActionConfig: {
      label: 'Cancel',
      handler: useCallback(() => {
        onCancel();
      }, []),
      testId:
        TEXT_CONTENT_IDS.CHILD_SESSION_NO_LONGER_VALID_CANCEL,
    },
  });

  const paymentMethodChannelConfig: PaymentMethodChannelConfigType =
    paymentMethodChannels.reduce<PaymentMethodChannelConfigType>(
      (acc, paymentMethodChannel) => {
        if (
          paymentMethodChannel.type === 'GOOGLE_PAY' ||
          paymentMethodChannel.type === 'APPLE_PAY'
        ) {
          return acc;
        }
        return [
          ...acc,
          {
            enabled:
              paymentMethodChannel.type === 'TELEPHONIC_ENTRY' &&
              hasTelephonicEntryFailedAttempsExceeded
                ? false
                : paymentMethodChannel.enabled,
            type: paymentMethodChannel.type,
            ...PaymentMethodChannelConfig[
              paymentMethodChannel.type
            ],
          },
        ];
      },
      [],
    );

  useEffect(() => {
    onLoadComplete();
  }, []);

  return (
    <Box>
      {showBackButton && showActionsOnForm && onBackClick ? (
        <BackButton
          label="Back"
          onClick={onBackClick}
          testId={
            TEXT_CONTENT_IDS.PAYMENT_METHOD_CHANNEL_SELECTOR_BACK_BUTTON
          }
        />
      ) : null}

      <PageTitle
        title={title}
        subTitle={getPaymentMethodChannelSelectorSubTitle(
          userJourney,
        )}
        titleId={TITLE_IDS.PAYMENT_METHOD_CHANNEL_SELECTOR_TITLE}
        subTitleId={
          TITLE_IDS.PAYMENT_METHOD_CHANNEL_SELECTOR_SUBTITLE
        }
      />

      <form>
        <Box style={{ marginBottom: '8px' }}>
          {paymentMethodChannelConfig?.map((channelConfig) => (
            <PaymentMethodChannelOption
              {...channelConfig}
              onClick={onDone}
              testId={`${userJourney}_${channelConfig.type}`}
              key={`${userJourney}_${channelConfig.type}`}
              enabled={channelConfig.enabled}
            />
          ))}
        </Box>

        {showActionsOnForm ? (
          <Button
            data-testid={
              TEXT_CONTENT_IDS.CHILD_SESSION_NO_LONGER_VALID_CANCEL
            }
            fullWidth
            color="secondary"
            variant="contained"
            onClick={onCancel}
          >
            Cancel
          </Button>
        ) : null}
      </form>
    </Box>
  );
};

export default PaymentMethodChannelSelector;
