import type GetBackButtonDetailsReturn from '../types/GetBackButtonDetailsReturn';

/**
 * NOTE: this is probably not a valid scenario anymore. We don't have a scenario
 * where we go back to the wallet add payment method screen. We can probably remove this.
 */
export const BACK_TO_WALLET_RESULT: GetBackButtonDetailsReturn =
  {
    showBackButton: true,
    scenario: 'BACK_TO_WALLET',
  };

export const BACK_TO_CHANNEL_SELECTOR_RESULT: GetBackButtonDetailsReturn =
  {
    showBackButton: true,
    scenario: 'BACK_TO_CHANNEL_SELECTOR',
  };

export const BACK_TO_WALLET_MANAGEMENT_RESULT: GetBackButtonDetailsReturn =
  {
    showBackButton: true,
    scenario: 'BACK_TO_WALLET_MANAGEMENT',
  };

export const HIDE_BACK_BUTTON: GetBackButtonDetailsReturn = {
  showBackButton: false,
};

export const BACK_TO_SYCURIO_TELEPHONIC_ENTRY: GetBackButtonDetailsReturn =
  {
    showBackButton: true,
    scenario: 'BACK_TO_SYCURIO_TELEPHONIC_ENTRY',
  };
