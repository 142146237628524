import type { AxiosInstance } from 'axios';
import axios from 'axios';

import type { HttpServiceError } from '../../api/apis';
import {
  authInterceptor,
  errorInterceptor,
  getHeaders,
  telemetryInterceptor,
} from '../../api/apis';
import { isRetriable } from '../../api/utils/retry';
import type { AppEnv } from '../../shared/types/app/AppEnv';

import { settingsApi } from './settingsApi';
import type {
  ExtendedAxiosError,
  ExtendedAxiosRequestConfig,
} from './types';

type HttpClientServiceInitProps = {
  client?: HttpClient;
  appEnv: AppEnv;
  onLoadSettingsFailed?: ((reason?: any) => void) | undefined;
  onUnAuthorizedCallback?: (() => void) | undefined;
  onError?: ((args: HttpServiceError) => void) | undefined;
  config?: Pick<
    ExtendedAxiosRequestConfig,
    'retryAttempts' | 'retryAfter' | 'retryCondition'
  >;
};
class HttpClientService {
  /**
   * This is (I think) used to make `this.request` wait for `this.init` to finish but since that is synchronous, I
   * don't think this is needed. Maybe it's a safety net where we can call `httpClientService.init` and elsewhere
   * call `httpClientService.request` and it will wait for `httpClientService.init` to finish?
   */
  private initPromiseResolve: () => void;

  /**
   * TODO: I'm not sure if this is actually is serving a purpose.
   * I can't follow any code path where this is handled.
   */
  private initPromiseFailed: (reason?: any) => void;

  private getHttpClientPromise: Promise<void> =
    new Promise<void>((resolve, reject) => {
      this.initPromiseResolve = resolve;
      this.initPromiseFailed = reject;
    });

  private client: HttpClient;

  setClient = (client: HttpClient) => {
    this.client = client;
  };

  getClient = (): HttpClient => {
    if (!this.client) {
      throw new Error(
        'httpClientService: client is not initialized',
      );
    }
    return this.client;
  };

  async request<T>({
    requestParams: { headers, ...requestParams },
    api,
  }: {
    requestParams: RequestParams;
    api?: 'CUSTOMER' | 'PAYMENT' | 'WALLET';
  }) {
    // wait for `this.getHttpClientPromise` to resolve. This is resolved in `init` method below. Don't think this is actually serving a purpose.
    // TODO: Investigate and remove if not needed.
    await this.getHttpClientPromise;

    const httpClient = this.getClient();
    const standardHeaders = getHeaders();
    const baseURL = api
      ? await settingsApi.getApiURL({ api })
      : requestParams.baseURL;
    const request = {
      ...requestParams,
      headers: { ...headers, ...standardHeaders },
      baseURL,
    };

    return httpClient.request<T>(request);
  }

  init(props: HttpClientServiceInitProps) {
    try {
      const {
        retryAttempts = 5,
        retryAfter = 1000,
        retryCondition = (error: ExtendedAxiosError) =>
          isRetriable(error),
      } = props.config ?? {};

      const config: ExtendedAxiosRequestConfig = {
        retryAttempts,
        retryAfter,
        retryCondition,
      };

      const {
        client = axios.create(config),
        onUnAuthorizedCallback,
        onError,
      } = props;

      telemetryInterceptor(client as AxiosInstance);

      if (onUnAuthorizedCallback) {
        authInterceptor(
          client as AxiosInstance,
          onUnAuthorizedCallback,
        );
      }

      if (onError) {
        errorInterceptor(client as AxiosInstance, onError);
      }

      /**
       * Set the client so that it can be used in `request` method above.
       */
      this.setClient(client);

      /**
       * NOTE: This will be called every time httpClientService.init is called.
       * `settingsApi.init` has guards/"caching" in place to prevent it from loading the settings.json file multiple times.
       */
      settingsApi.init({ ...props, client });
      this.initPromiseResolve();
    } catch (ex) {
      this.initPromiseFailed(ex.message);
    }
  }

  getHttpClient = ({
    api,
  }: {
    api: 'CUSTOMER' | 'PAYMENT' | 'WALLET';
  }): HttpClient => {
    return {
      // This is what end users call:
      request: async <T>(requestParams: RequestParams) =>
        this.request<T>({
          requestParams,
          api,
        }),
    };
  };
}

export const httpClientService = new HttpClientService();
