import type { Method } from 'axios';

import { httpClientService } from '../settingsApi/httpClientService';

type SycurioEventType =
  | 'startSycurio'
  | 'onDone'
  | 'onCancel'
  | 'onBackClick'
  | 'onError';
export default async function commonSycurioTelephonicEntryService({
  checkoutSessionId,
  method = 'POST',
  eventType,
}: {
  checkoutSessionId: string;
  method?: Method;
  eventType?: SycurioEventType;
}) {
  let data: Record<
    'context',
    'PAYMENT_METHOD_TOKENIZED' | 'CANCELLED'
  > = {
    context: 'PAYMENT_METHOD_TOKENIZED',
  };
  if (eventType !== 'startSycurio') {
    data = {
      context: 'CANCELLED',
    };
  }
  const httpClient = httpClientService.getHttpClient({
    api: 'WALLET',
  });
  return httpClient.request({
    url: `/checkout-sessions/${checkoutSessionId}/telephonic-entry`,
    method,
    data,
  });
}
